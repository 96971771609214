// ** React Imports
import { ReactNode } from 'react'

// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

// ** Store Imports
import { store } from 'src/store'
import { Provider } from 'react-redux'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import 'src/configs/i18n'
import { defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'

import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Fake-DB Import
// import 'src/@fake-db'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import AclGuard from 'src/layouts/components/auth/UserAclGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import AuthGuard from 'src/layouts/components/auth/UserAuthGuard'
import GuestGuard from 'src/layouts/components/auth/UserGuestGuard'

// ** Spinner Import
// import Spinner from 'src/@core/components/spinner'
import UserSpinner from 'src/layouts/components/UserSpinner'

// ** Contexts
// import { AuthProvider } from 'src/context/AuthContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
// import 'prismjs/components/prism-jsx'
// import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

import 'src/iconify-bundle/icons-bundle-react'

// ** Global css styles
import '../../styles/globals.css'

import { useState, useEffect } from 'react'

// import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider, Session } from '@supabase/auth-helpers-react'
import { MyUserContextProvider } from '../utils/useUser'
import type { Database } from '../utils/types_db'

import { Analytics } from '@vercel/analytics/react'
import ChatterGoScript from 'src/layouts/components/chattergo/chattergo'

import '@fontsource/montserrat' // Defaults to weight 400
import '@fontsource/montserrat/400.css' // If you need a specific weight
import '@fontsource/roboto' // Defaults to weight 400
import '@fontsource/roboto/400.css' // If you need a specific weight
import '@fontsource/open-sans' // Defaults to weight 400
import '@fontsource/open-sans/400.css' // If you need a specific weight
import '@fontsource/oswald' // Defaults to weight 400
import '@fontsource/oswald/400.css' // If you need a specific weight
import { Inter } from 'next/font/google'

const inter = Inter({ subsets: ['latin'] })

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

type GuardProps = {
  authGuard: boolean
  guestGuard: boolean
  children: ReactNode
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  if (guestGuard) {
    console.log('_app - GuestGuard')
    return <GuestGuard fallback={<UserSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    console.log('_app - not guestGuard not authGuard, return children')
    return <>{children}</>
  } else {
    console.log('_app - AuthGuard')
    return <AuthGuard fallback={<UserSpinner />}>{children}</AuthGuard>
  }
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false
  //  const getLayout =
  //    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)
  const originalGetLayout =
    Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

  const getLayout = (page: ReactNode) =>
    originalGetLayout(
      <>
        {page}
        {!guestGuard && authGuard && !isMobile && <ChatterGoScript />}
      </>
    )
  const setConfig = Component.setConfig ?? undefined

  const authGuard = Component.authGuard ?? true

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  //  const [supabaseClient] = useState(() => createBrowserSupabaseClient<Database>())
  const [supabaseClient] = useState(() => createPagesBrowserClient())

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${themeConfig.templateName} - Chattable Agentic AI Assistant`}</title>
          <meta
            name='description'
            content={`${themeConfig.templateName} – CHATTERgo - Chattable Agentic AI Assistant`}
          />
          <meta
            name='keywords'
            content='Shopify Chatbot, Magento Chatbot, LLM, Openai, Chat, Chatgpt, llama, GPT, KB, Actions, Workflow'
          />
          <meta name='viewport' content='initial-scale=1, width=device-width' />
        </Head>
        <SessionContextProvider supabaseClient={supabaseClient}>
          <MyUserContextProvider>
            <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      <Guard authGuard={authGuard} guestGuard={guestGuard}>
                        <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard} authGuard={authGuard}>
                          {getLayout(<Component {...pageProps} />)}
                        </AclGuard>
                      </Guard>
                      <ReactHotToast>
                        <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                      </ReactHotToast>
                    </ThemeComponent>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </MyUserContextProvider>
        </SessionContextProvider>
      </CacheProvider>
      <Analytics />
    </Provider>
  )
}

export default App
