import { useState, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/layouts/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown, {
  NotificationsType
} from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import ShortcutsDropdown, { ShortcutsType } from 'src/@core/layouts/components/shared-components/ShortcutsDropdown'

// ** Hook Import
// import { useAuth } from 'src/hooks/useAuth'

import { useUser } from 'src/utils/useUser'
import { useSupabaseClient } from '@supabase/auth-helpers-react'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}
{
  /*}
const notifications: NotificationsType[] = [
  {
    meta: '1 Jan',
    //    avatarAlt: 'Flora',
    title: 'Order Status Support',
    //    avatarImg: '/images/avatars/4.png',
    subtitle: 'Now customers can ask the fulfilment status of their orders'
  },
  {
    meta: '1 Jan',
    title: 'Close button image',
    subtitle: 'Now at Shopify theme editor, you can change the close button image'
    //    avatarColor: 'primary',
    //    avatarText: 'Robert Austin',
  }
]

*/
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const [notifications, setNotifications] = useState<NotificationsType[]>([])

  // ** Hook
  const auth = useUser()
  const supabase = useSupabaseClient()

  const shortcuts: ShortcutsType[] = [
    {
      title: 'Commerce AI',
      url: '/apps/public/widget/',
      subtitle: 'eCommerce AI Assistant',
      icon: 'mdi:shopping-outline',
      app: 'public'
    },
    {
      title: 'Workspace AI',
      url: '/apps/chat/',
      subtitle: 'Company AI Assistant',
      icon: 'mdi:account-tie-voice',
      app: 'internal'
    }
  ]

  // Add translation shortcut if Shopify
  if (auth.userDetails?.organizations?.isShopify === true) {
    shortcuts.push({
      title: 'Translations AI',
      url: '/translations/',
      subtitle: 'Agentic AI Translations',
      icon: 'mdi:translate',
      app: 'translations'
    })
  }

  useEffect(() => {
    const getNotifications = async () => {
      let lastRead
      if (auth.userDetails?.read_notifications) {
        lastRead = auth.userDetails?.read_notifications
      } else {
        lastRead = '2023-01-01'
      }
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .gt('meta', lastRead)
        .order('meta', { ascending: false })
      if (error) {
        console.log('Error:', error)
      } else {
        console.log('notification data: ', data)
        setNotifications(data)
      }
    }
    getNotifications()
  }, [supabase, auth.userDetails?.read_notifications])

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden && !settings.navHidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <Icon icon='mdi:menu' />
          </IconButton>
        ) : null}
        {/*}
        {auth.user && <Autocomplete hidden={hidden} settings={settings} />}
*/}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {auth.user && (
          <>
            <IconButton color='inherit' onClick={() => window.open('https://help.chattergo.com', '_blank')}>
              <Icon icon='mdi:help-circle-outline' />
            </IconButton>
          </>
        )}
        {/*}        <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown settings={settings} notifications={notifications} />
        <ShortcutsDropdown settings={settings} shortcuts={shortcuts} />
        {auth.user && (
          <>
            {/*}            <ShortcutsDropdown settings={settings} shortcuts={shortcuts} />
            <NotificationDropdown settings={settings} notifications={notifications} />
            */}
            <UserDropdown settings={settings} />
          </>
        )}
      </Box>
    </Box>
  )
}

export default AppBarContent
